<template>
  <div :width="1280">
    <div>
      <div style="text-align: center;color: #5E6470;font-size: 12px">
        <span>从下方选择试卷</span>
      </div>
      <div class="selectTable">
        <div class="top">
          <div>
            <Select v-model="paperId" style="width:200px" @on-change="selectBank">
              <Option v-for="item in testPapers" :value="item.paperBankId" :key="item.paperBankId">{{ item.name }}</Option>
            </Select>
          </div>
          <Input search placeholder="请输入查找信息" class="search" v-model="keyword" @on-search="search" />
        </div>
        <Table
          highlight-row
          @on-selection-change="selectPaper"
          ref="table"
          :height="500"
          :data="paperData"
          :columns="paperColumns"
          :loading="paperLoading"
          stripe
        >
          <template slot-scope="{ row }" slot="level">
            <span>{{ getDifficulty(row.level) }}</span>
          </template>
          <template slot-scope="{ row, index }" slot="createdTime">
            <span>{{ $formatTime(row.createdTime, 'yyyy-MM-dd hh:mm') }}</span>
          </template>
          <template slot-scope="{ row }" slot="creatorName">
            <span>{{ row.creatorName }}</span>
          </template>
        </Table>
      </div>
    </div>
    <div slot="footer" style="text-align: center;margin-top: 20px">
      <Button style="width: 90px" @click="cancel">取消</Button>
      <Button type="primary" style="width: 90px;margin-left: 10px" @click="addExamNew" :loading="confirmLoading">确定</Button>
    </div>
  </div>
</template>
<script>
import DeleteModal from '@components/common/DeleteModal'
import paper from '@api/paper'
import { difficulty } from '@util/difficulty'
import exam from '@api/exam'
import courseApi from '@api/course'
export default {
  name: 'CourseQuestionnaire',
  props: {
    itGroupId: {
      type: Number,
      required: true
    },
    itemExamId: {
      type: Array
    }
  },
  data() {
    return {
      examId: this.itemExamId,
      questionnaireName: '',
      questionnaireId: '',
      paperLoading: false,
      paperData: [],
      tableH: document.body.clientHeight - 204,
      keyword: '',
      selectPaperId: [],
      paperId: '',
      testPapers: [],
      confirmLoading: false,
      addModal: false,
      loading: false,
      deleteVisible: false,
      deleteLoading: false,
      paperColumns: [
        {
          type: 'selection',
          align: 'center',
          className: 'selection-column'
        },
        {
          title: '考试名称',
          key: 'name'
        },
        {
          title: '试卷名称',
          key: 'paper'
        },
        {
          title: '总分',
          key: 'total'
        },
        {
          title: '题量',
          key: 'questionAmount'
        },
        {
          title: '难度',
          slot: 'level'
        },
        {
          title: '创建时间',
          slot: 'createdTime'
        },
        {
          title: '创建人',
          slot: 'creatorName'
        }
      ],
      tableData: [],
      detailModal: false,
      detailData: [],
      questionnaireAnswers: [],
      page: 0,
      size: 10,
      sort: '',
      searchContent: ''
    }
  },
  created() {
    // this.getAllPaperBanks()
    // this.getQuestionnaire()
  },
  methods: {
    getAllPaperBanks() {
      paper.getAllPaperBanks().then(res => {
        this.testPapers = res.res
        this.selectBank(res.res[0].paperBankId)
      })
    },
    getDifficulty(difficulty) {
      switch (difficulty) {
        case 0:
          return '简单'
        case 1:
          return '中等'
        case 2:
          return '困难'
      }
    },
    addExamNew() {
      let groupElementForm = {
        elementIds: this.selectPaperId,
        facilityType: 1,
        groupId: this.itGroupId
      }
      courseApi.getGroupElements(groupElementForm).then(res => {
        this.$message.success('添加成功')
        this.addCourse = false
        this.$emit('on-ok')
        this.cancel()
      })
    },
    getQuestionnaire() {
      this.loading = true
      exam
        .getQuestionnaire(this.itGroupId)
        .then(res => {
          if (res.res !== undefined) {
            this.tableData = [res.res]
          } else {
            this.tableData = []
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    selectBank(val) {
      if (val) {
        this.paperId = val
        this.keyword = ''
        this.getPapers(val)
      }
    },
    getPapers(id) {
      this.paperLoading = true
      exam
        .getPaperBankExams(id, this.sort, this.page, this.size, this.keyword)
        .then(res => {
          this.paperData = res.res.data
          for (let i = 0; i < this.paperData.length; i++) {
            for (let j = 0; j < this.itemExamId.length; j++) {
              if (this.paperData[i].examId === this.itemExamId[j]) {
                this.paperData[i]._checked = true
              }
            }
          }
          // this.paperData[2]._checked = true
          this.paperLoading = false
        })
        .catch(() => {
          this.paperLoading = false
        })
    },
    selectPaper(data) {
      let row = []
      data.map(item => {
        row.push(item.examId)
      })
      this.selectPaperId = row
    },
    // checkLevel(num) {
    //   return difficulty[num].label
    // },
    search() {
      if (this.paperId === '') {
        this.$message.warning('请选择试卷库')
        return
      }
      this.paperLoading = true
      this.searchContent = this.keyword
      this.getPapers(this.paperId)
    },
    cancel() {
      this.addModal = false
      this.paperData = []
      this.paperId = ''
      this.keyword = ''
      this.confirmLoading = false
      this.$emit('on-cancle')
    }
  }
}
</script>

<style scoped lang="less">
.table-container {
  ::v-deep .flex-table {
    .name-column {
      flex: auto;
    }
    .other-column {
      width: 20%;
    }
    .ivu-table-tip {
      display: none;
    }
  }
}
.selectTable {
  box-shadow: 0px 0px 5px rgba(7, 27, 41, 0.1);
  margin-top: 16px;
  .top {
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    .search {
      width: 300px;
      ::v-deep .ivu-input {
        border: 0;
        border-bottom: 1px solid #ccc;
        border-radius: 0;

        &:focus {
          box-shadow: 0 1px 0 rgba(45, 140, 240, 0.2);
        }
      }
    }
  }
}
</style>
