<template>
  <div class="course-container">
    <div style="display: flex;margin-top: 10px;height: 84%">
      <div class="left">
        <div class="top" style="display: flex;justify-content: space-between">
          <div>
            <svg class="icon" aria-hidden="true" style="height: 14px;width: 16px;margin-right: 5px;color: #6a8abeff">
              <use xlink:href="#icon-jiagoufenxiao"></use>
            </svg>
            <span class="lesson">课程分类 {{ this.postId === 0 ? '' : '(' + this.showName + ')' }}</span>
          </div>
        </div>
        <div class="tree">
          <el-tree
            v-if="treeData.length !== 0"
            :expand-on-click-node="false"
            node-key="id"
            :data="treeData"
            :props="defaultProps"
            :highlight-current="true"
          >
            <div
              class="custom-tree-node"
              style="width: 100%;display: flex;justify-content: space-between;height: 100%;line-height: 30px"
              node-key="data.id"
              slot-scope="{ node, data }"
              :style="showBackgrong === true && data.id === 0 ? 'background-color: #e1e8f2' : ''"
            >
              <div @click="choosePost(data)" style="width: 100%">
                <div>
                  {{ node.label }}
                </div>
              </div>
            </div>
          </el-tree>
        </div>
      </div>
      <div style="flex: auto;">
        <div class="table-container bg-shadow" style="height: 100%">
          <Table class="flex-table" stripe :columns="tableHeader" :data="courseCard.data" :loading="loading" @on-selection-change="getLesson">
            <template slot-scope="{ row, index }" slot="name">
              <span>{{ row.name }}</span>
            </template>
            <template slot-scope="{ row, index }" slot="description">
              <span class="desc">{{ row.description.length > 15 ? row.description.substr(0, 15) + '...' : row.description }}</span>
            </template>
            <template slot-scope="{ row, index }" slot="type">
              <span v-for="(item, i) in row.posts" :key="i">{{ item.name }}<span v-if="i !== row.posts.length - 1">， </span></span>
            </template>
            <template slot-scope="{ row, index }" slot="creatorName">
              <span style="margin-left: 20px">{{ row.creatorName }}</span>
            </template>
            <template slot-scope="{ row, index }" slot="createTime">
              <span>{{ $formatTime(row.createTime, 'yyyy-MM-dd hh:mm') }}</span>
            </template>
            <template slot-scope="{ row, index }" slot="action">
              <div class="operation" @click="gotoCourseDetail(row)">
                <img src="../../../assets/查看.png" height="14" width="18" />
              </div>
            </template>
          </Table>
          <div class="page">
            <div style="float: right;">
              <Page
                show-total
                show-sizer
                show-elevator
                :total="this.courseCard.total"
                @on-change="changePage"
                @on-page-size-change="changePageSize"
                :current="pageNumber + 1"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="margin: 40px 0;display: flex;justify-content: center">
      <Button style="width: 80px" @click="cancle">取消</Button>&nbsp;&nbsp;
      <Button type="primary" style="width: 80px;margin-left: 20px" :loading="loading" @click="addNewLesson">确认</Button>
    </div>
  </div>
</template>
<script>
import courseApi from '@api/course'
import DeleteModal from '@components/common/DeleteModal'
import CourseCard from './CourseCard'
import EditModal from './EditModal'
import userApi from '@api/user'
import JYIcon from '@components/common/JYIcon'
import functionApi from '@api/functionApi'
import NewCourse from '@components/manage/course/NewCourse'

export default {
  props: {
    itGroupId: {
      type: Number,
      required: true
    },
    addCourse: {
      type: Boolean,
      default: false
    },
    itemCourseId: {
      type: Array
    }
  },
  components: {
    NewCourse,
    EditModal,
    CourseCard,
    DeleteModal,
    JYIcon
  },
  created() {
    this.showBackgrong = true
    if (this.$route.query.showName !== undefined) {
      this.showName = this.$route.query.showName
    }
    if (this.$route.query.postId !== undefined) {
      this.postId = this.$route.query.postId
    }
    // this.getCourseList()
    // this.getRooter()
  },
  data() {
    return {
      showBackgrong: false,
      itemCourseid: this.itemCourseId,
      elementIds: [],
      lessonId: {},
      categoryId: 0,
      showName: '',
      cardH: document.body.clientHeight - 204,
      treeData: [],
      edit: false,
      loading: false,
      loadingText: '加载中',
      backTop: false,
      tableHeader: [
        {
          type: 'selection',
          align: 'center',
          className: 'selection-column'
        },
        {
          title: '课程名称',
          slot: 'name',
          key: 'name',
          sortable: true,
          className: 'name-column',
          ellipsis: true
        },
        {
          title: '描述',
          slot: 'description',
          className: 'description-column',
          ellipsis: true
        },
        {
          title: '创建者',
          slot: 'creatorName',
          className: 'type-column'
        },
        {
          title: '创建时间',
          slot: 'createTime',
          className: 'status-column'
        },
        {
          title: '操作',
          slot: 'action',
          className: 'action-column'
        }
      ],
      defaultProps: {
        children: 'categoryTreeVOS',
        label: 'title'
      },
      courses: {
        data: [],
        total: 0
      },
      editForm: {},
      pageNumber: 0,
      pageSize: 10,
      courseCard: {
        total: 0,
        page: 0,
        data: []
      },
      managerListHeight: window.innerHeight,
      courseId: [],
      postId: 0,
      patentId: 0
    }
  },
  watch: {
    searchKeyword() {
      this.pageNumber = 0
      this.pageSize = 10
      this.courseCard.page = 0
    }
  },
  computed: {
    size() {
      let columnNumber = Number.parseInt((window.document.body.clientWidth - 180 - 20 * 2 + 20) / 330)
      let rowNumber = Number.parseInt((window.document.body.clientHeight - 70 - 34 - 152) / 370) + 1
      return rowNumber * columnNumber
    }
  },
  methods: {
    cancle() {
      this.$emit('on-cancle')
    },
    addNewLesson() {
      let groupElementForm = {
        elementIds: this.elementIds,
        facilityType: 0,
        groupId: this.itGroupId
      }
      courseApi.getGroupElements(groupElementForm).then(res => {
        this.$message.success('添加成功')
        // this.addCourse = false
        this.$emit('on-ok')
      })
    },
    getLesson(data) {
      let row = []
      this.lessonId = data
      data.map(item => {
        row.push(item.courseId)
      })
      this.elementIds = row
    },
    getRooter() {
      courseApi.getCategoryTree(this.categoryId).then(res => {
        let arr = JSON.stringify(res.res)
        this.treeData = JSON.parse(arr.replace(/name/g, 'title'))
        this.treeData.unshift({
          id: 0,
          title: '所有课程'
        })
      })
    },
    choosePost(data) {
      this.showBackgrong = false
      this.showName = data.title
      this.postId = data.id
      this.getCourseList()
    },
    getCourseList() {
      this.loading = true
      courseApi.getClassification(this.pageNumber, this.pageSize, this.postId).then(res => {
        this.courses.data = []
        this.courses.data = res.res.data
        this.courses.total = res.res.total
        this.courseCard.data = []
        this.courseCard.data = res.res.data
        this.courseCard.total = res.res.total
        this.loading = false
        for (let i = 0; i < this.itemCourseid.length; i++) {
          for (let j = 0; j < this.courseCard.data.length; j++) {
            if (this.itemCourseid[i] === this.courseCard.data[j].courseId) {
              this.courseCard.data[j]._checked = true
            }
          }
        }
      })
    },
    toNewCourse() {
      this.$router.push('/course/newPaper')
    },
    gotoCourseDetail(row) {
      this.$router.push({
        path: `/manage/course/detail/${row.courseId}`,
        query: {
          addCourse: this.addCourse,
          groupId: this.itGroupId,
          postId: this.postId,
          showName: this.showName
        }
      })
    },
    changePage(page) {
      this.pageNumber = page - 1
      this.handlePageChange()
    },
    changePageSize(size) {
      this.pageSize = size
      this.handlePageChange()
    },
    handlePageChange() {
      this.getCourseList()
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../theme/variables';
.cation {
  color: #6a8abeff;
  font-size: 12px;
  cursor: pointer;
}
.cation :hover {
  color: #3a4e64;
}
.course-container {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  height: 824px !important;
  width: 100%;
  background-color: @layout-body-background;
  position: relative;
  box-sizing: border-box;

  .func-bar {
    margin-bottom: 10px;
    width: 100%;
    flex: 0 0 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding-left: 20px;
    height: 56px;

    .filter-btn {
      margin-right: 20px;
    }

    ::v-deep .search-container {
      display: flex;
      align-items: center;
      .ivu-input {
        border: 0;
        border-bottom: 1px solid #ccc;
        border-radius: 0;
      }
      .ivu-input:focus {
        box-shadow: 0 1px 0 rgba(45, 140, 240, 0.2);
      }

      .advanced-search {
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
  .func {
    cursor: pointer;
    background-color: #f2f3f5;
    margin-bottom: 10px;
    width: 100%;
    flex: 0 0 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding-left: 20px;
    height: 56px;
    &:hover {
      background-color: #e4e9ef;
    }
    .filter-btn {
      margin-right: 20px;
    }

    ::v-deep .search-container {
      display: flex;
      align-items: center;
      .ivu-input {
        border: 0;
        border-bottom: 1px solid #ccc;
        border-radius: 0;
      }
      .ivu-input:focus {
        box-shadow: 0 1px 0 rgba(45, 140, 240, 0.2);
      }

      .advanced-search {
        font-size: 14px;
        cursor: pointer;
      }
    }
  }

  .table-container {
    width: 100%;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ::v-deep .flex-table {
      border-bottom: 1px solid #e8eaec;

      .ivu-table-row {
        cursor: pointer;
      }

      .status-container {
        display: flex;
        align-items: center;

        .status {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          margin-right: 4px;
        }
      }

      .operation {
        cursor: pointer;
        color: @primary-color;
        transition: all @default-transition;

        &:hover {
          color: @primary-hover-color;
        }
      }

      .selection-column {
        width: 60px;
      }

      .name-column {
        width: 20%;
      }

      .description-column {
        flex: auto;
        width: 20%;
        overflow: hidden !important;
      }

      .type-column {
        width: 18%;
      }

      .status-column {
        width: 18%;
      }

      .action-column {
        width: 100px;
      }
    }

    .page {
      flex: 0 0 auto;
      padding: 20px;
      font-size: 14px;

      .page-options {
        float: left;
        height: 32px;
        line-height: 32px;
      }
    }
  }
}

::v-deep .form {
  padding: 40px;
  text-align: center;

  .search-btn {
    width: 106px;
  }
}

.scroll-container {
  background-color: @layout-body-background;
  flex: 1 1 auto;
  overflow: auto;

  ::v-deep .ivu-scroll-wrapper {
    height: 100%;

    .ivu-scroll-container {
      height: 100% !important;
      overflow: scroll;

      .ivu-scroll-content {
        /*卡片阴影会被遮挡，故多 2px*/
        padding-left: 2px;
      }
    }
  }

  .course-list {
    display: flex;
    flex-flow: row wrap;
    margin-left: -20px;
    ::v-deep .course-card {
      margin: 0 0 20px 20px;
    }
  }
  .top {
    position: fixed;
    right: 20px;
    bottom: 100px;
    background: rgba(@primary-color, 0.7);
    color: #fff;
    text-align: center;
    border-radius: 2px;
    font-size: 20px;
    cursor: pointer;
    transition: background-color @default-transition;
    width: 40px;
    height: 40px;
    line-height: 40px;

    &:hover {
      background: rgba(@primary-color, 0.9);
    }

    svg {
      transform: rotate(180deg);
    }
  }
}

.manager ::v-deep .ivu-modal {
  text-align: center;
  .ivu-modal-body {
    margin-top: -30px;
  }
}
.managerList {
  height: 30px;
  border-radius: 4px;
  text-align: left;
  padding: 5px 0 0 10px;
  cursor: pointer;
  &:hover {
    background-color: #e4e9efff;
  }
  &:focus {
    background-color: #e4e9efff;
    outline: none;
    font-weight: bold;
  }
}
.left {
  min-width: 300px;
  box-shadow: 0px 0px 5px rgba(7, 27, 41, 0.1);
  margin-right: 20px;
  .top {
    height: 56px;
    background: #e4e9ef;
    font-size: 16px;
    color: #3a4e64;
    font-weight: 400;
    padding: 16px 23px;
    display: flex;
    align-items: center;
  }
  .tree {
    padding: 15px 12px;
    text-align: left;
    height: calc(100vh - 196px);
    overflow: auto;
  }
}
.new {
  ::v-deep .ivu-modal-footer {
    display: none;
  }
}
::v-deep .ivu-tree ul {
  font-size: 14px;
  color: #5e6470;
}
.show {
  cursor: pointer;
  &:hover {
    color: #6a8abeff;
  }
}
.addIt {
  cursor: pointer;
  font-size: 14px;
  color: #5e6470ff;
}
.showTable {
  margin-right: 20px;
  cursor: pointer;
}
.addClass {
  line-height: 32px;
  position: relative;
  left: 160px;
  cursor: pointer;
}
.lesson {
  margin-left: 8px;
  width: 56px;
  height: 14px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 24px;
  color: rgba(58, 78, 100, 1);
  opacity: 1;
}
.itClose {
  margin-right: 4px;
  width: 24px;
  height: 12px;
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 16px;
  opacity: 1;
}
.showLeft {
  cursor: pointer;
}
.el-tree {
  position: relative;
  cursor: default;
  background: none;
  color: #606266;
}
::v-deep .el-tree-node__expand-icon {
  color: black;
}
::v-deep .el-tree-node__content:hover {
  background-color: #e4e9ef;
}
::v-deep .el-tree-node__content {
  min-height: 30px;
}
[data-v-5c6fb3c5]:hover {
  color: #606266;
}
.lessons {
  white-space: nowrap;
  margin: 0 0 20px 10px;
  cursor: pointer;
  width: 56px;
  height: 14px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 21px;
  color: rgba(58, 78, 100, 1);
  opacity: 1;
  & :hover {
    color: rgba(58, 78, 100, 1);
  }
}
.button-container :hover {
  color: #ffffff;
}
::v-deep.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  background-color: #e1e8f2;
}
.course-container[data-v-7d71b882] {
  background-color: white;
}
</style>
