var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"width":1280}},[_c('div',[_vm._m(0),_c('div',{staticClass:"selectTable"},[_c('div',{staticClass:"top"},[_c('div',[_c('Select',{staticStyle:{"width":"200px"},on:{"on-change":_vm.selectBank},model:{value:(_vm.paperId),callback:function ($$v) {_vm.paperId=$$v},expression:"paperId"}},_vm._l((_vm.testPapers),function(item){return _c('Option',{key:item.paperBankId,attrs:{"value":item.paperBankId}},[_vm._v(_vm._s(item.name))])}),1)],1),_c('Input',{staticClass:"search",attrs:{"search":"","placeholder":"请输入查找信息"},on:{"on-search":_vm.search},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}})],1),_c('Table',{ref:"table",attrs:{"highlight-row":"","height":500,"data":_vm.paperData,"columns":_vm.paperColumns,"loading":_vm.paperLoading,"stripe":""},on:{"on-selection-change":_vm.selectPaper},scopedSlots:_vm._u([{key:"level",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.getDifficulty(row.level)))])]}},{key:"createdTime",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(_vm.$formatTime(row.createdTime, 'yyyy-MM-dd hh:mm')))])]}},{key:"creatorName",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.creatorName))])]}}])})],1)]),_c('div',{staticStyle:{"text-align":"center","margin-top":"20px"},attrs:{"slot":"footer"},slot:"footer"},[_c('Button',{staticStyle:{"width":"90px"},on:{"click":_vm.cancel}},[_vm._v("取消")]),_c('Button',{staticStyle:{"width":"90px","margin-left":"10px"},attrs:{"type":"primary","loading":_vm.confirmLoading},on:{"click":_vm.addExamNew}},[_vm._v("确定")])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"text-align":"center","color":"#5E6470","font-size":"12px"}},[_c('span',[_vm._v("从下方选择试卷")])])}]

export { render, staticRenderFns }