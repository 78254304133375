export const difficulty = {
  '0': {
    value: 0,
    label: '简单'
  },
  '1': {
    value: 1,
    label: '中等'
  },
  '2': {
    value: 2,
    label: '困难'
  }
}
