import config from '../config/config'
import { axios } from './index'

const baseUrl = config.baseUrl + '/exam/api/v1'
const examUrl = config.baseUrl + '/exam/api/v2'
const questionUrl = config.baseUrl + '/question/api/v1'
export default {
  getEvaByExamReport(form) {
    //获取考试评价
    return axios.get(`${baseUrl}/exam/get/exam/comment?examId=${form}`)
  },
  saveEvaByExamReport(form) {
    //保存考试评价
    return axios.post(`${baseUrl}/exam/add/exam/comment`, form)
  },
  //考试答题情况
  getNewQuestions(examId) {
    return axios.get(`${questionUrl}/exam/new/questions/rate?examId=${examId}`)
  },
  // 技能评测结果分析报告
  abilityReport(answerPaperId) {
    return axios.post(`${baseUrl}/enter/report/get/cache/analyse/report?answerPaperId=${answerPaperId}`)
  },
  //查询考试是否已存在
  queryExist(examName) {
    return axios.get(`${examUrl}/exam/management/exam/is/exist?examName=${examName}`)
  },
  //保存考试与所用试卷
  savePaper(examId, paperId) {
    return axios.post(baseUrl + `/public/save/exam/paper/relation?examId=${examId}&paperId=${paperId}`)
  },
  getScoreByNum(id) {
    return axios.get(baseUrl + `/statistic/exam/score/num?examId=${id}`)
  },
  getScoreByRange(id, size) {
    return axios.get(baseUrl + `/statistic/exam/score/range?examId=${id}&sectionSize=${size}`)
  },
  getExamQuestionStatistic(eId, qId) {
    return axios.get(baseUrl + `/statistic/question/exam/answer-paper?examId=${eId}&questionId=${qId}`)
  },
  getCodeAnal(answerPaperId, paperId) {
    // 获取个人编程的能力分析
    return axios.get(baseUrl + '/code/exam/codeRecordInfo?answerPaperId=' + answerPaperId + '&paperId=' + paperId)
  },
  getExamPersonalReport(answerPaperId, paperId) {
    // 获取个人的评估报告
    return axios.get(baseUrl + '/enter/exam/personal/result?answerPaperId=' + answerPaperId + '&paperId=' + paperId)
  },
  getPracticeDetail(practiceId) {
    return axios.get(`${baseUrl}/practice/${practiceId}`)
  },
  updatePractice(data) {
    return axios.post(`${baseUrl}/practice/update`, data)
  },
  closePractice(practiceId) {
    return axios.post(`${baseUrl}/practice/close/${practiceId}`)
  },
  getExamDetail(examId) {
    return axios.get(`${baseUrl}/exam/detail/${examId}`)
  },
  getExamineeDetail(data) {
    return axios.post(`${baseUrl}/exam/detail/examinee`, data)
  },
  getUserExamAnswerPaper(examId, userId) {
    return axios.get(`${baseUrl}/exam/answerPaper/${userId}/${examId}`)
  },
  getUserExamDetail(answerPaperId) {
    return axios.get(`${baseUrl}/exam/answer/${answerPaperId}`)
  },
  getExamStatistics(examId) {
    return axios.get(`${baseUrl}/exam/statistic/${examId}`)
  },
  getExamList(page, size) {
    return axios.get(`${baseUrl}/exercise/exam/get?page=${page}&size=${size}`)
  },
  newGetExamList(page, size) {
    return axios.get(`${baseUrl}/exercise/exam/get/exams?page=${page}&size=${size}`) //新的接口
  },
  delExam(examId) {
    // 该接口是删除考试，但刷新后再次出现
    // return axios.post(`${baseUrl}/exercise/exam/delete?examId=${id}`)
    // 更换接口
    return axios.post(`${baseUrl}/exercise/exam/soft/delete`, examId)
  },
  addExam(data) {
    return axios.post(`${baseUrl}/exam/add`, data)
  },
  /**
   * 获取教师需要批阅的题目详情列表
   * @param examId
   * @returns {AxiosPromise<any>}
   */
  getRemarkDetailList(examId) {
    return axios.get(`${baseUrl}/mark/exam/${examId}`)
  },
  /**
   * 给用户提交的题目设置得分
   * @param answerPaperId
   * @param answerPaperQuestionId
   * @param userScore
   * @returns {AxiosPromise<any>}
   */
  remarkQuestion(answerPaperId, answerPaperQuestionId, userScore) {
    return axios.post(`${baseUrl}/mark/correct?answerPaperId=${answerPaperId}&answerPaperQuestionId=${answerPaperQuestionId}&userScore=${userScore}`)
  },
  /**
   * 获取当前考试的阅卷分配情况
   * @param examId
   * @returns {AxiosPromise<any>}
   */
  getRemarkAssignmentList(examId) {
    return axios.post(`${baseUrl}/mark/exam/access/questions?examId=${examId}`)
  },
  /**
   * 分配某次考试的阅卷老师
   * @param examId
   * @param allocation
   * @returns {AxiosPromise<any>}
   */
  assignRemarkTeachers(examId, allocation) {
    const payload = {
      examId,
      allocation
    }
    return axios.post(`${baseUrl}/mark/exam/assign/`, payload)
  },
  /**
   * 获取教师参与阅卷的考试列表
   * @param teacherId
   * @returns {AxiosPromise<any>}
   */
  getRemarkExamList(teacherId) {
    return axios.get(`${baseUrl}/mark/assignment/teacher?teacherId=${teacherId}`)
  },

  /**
   * 获取考试统计全量数据
   * @param pageNumber
   * @param pageSize
   * @returns {AxiosPromise<any>}
   */
  getAllExamStatistics(pageNumber = 1, pageSize = 5) {
    return axios.get(`${baseUrl}/statistic/exam?page=${pageNumber}&pageSize=${pageSize}`)
  },
  // 查看某场考试的部分结果统计信息
  getExamSameResultInfo(examId) {
    return axios.get(`${baseUrl}/exam/new/statistic/${examId}`)
  },
  // 企业评测
  addCandidate(data) {
    return axios.post(`${baseUrl}/enter/candidate/add`, data)
  },
  editCandidateCode(id, code) {
    return axios.post(`${baseUrl}/enter/candidate/code/edit?candidateId=${id}&candidateCode=${code}`)
  },
  refreshCandidate(id) {
    return axios.post(`${baseUrl}/enter/candidate/code/refresh?candidateId=${id}`)
  },
  delCandidate(id) {
    return axios.post(`
    ${baseUrl}/enter/candidate/soft/delete?candidateId=${id}`)
  },
  editCandidate(id, data) {
    return axios.post(`${baseUrl}/enter/candidate/edit?candidateId=${id}`, data)
  },
  addCandidateExam(id, data) {
    return axios.post(`${baseUrl}/enter/candidate/exam/add?candidateId=${id}`, data)
  },
  delCandidateExam(id, data) {
    return axios.post(`${baseUrl}/enter/candidate/exam/delete?candidateId=${id}`, data)
  },
  getCandidate(page, size) {
    return axios.get(`${baseUrl}/enter/candidate/get?page=${page}&size=${size}`)
  },
  getCandidateExam(id) {
    return axios.get(`${baseUrl}/enter/candidate/exams/get?candidateId=${id}`)
  },
  getExam(page, size) {
    return axios.get(`${baseUrl}/enter/exam/get?page=${page}&size=${size}`)
  },
  getExaminee(id, page, size) {
    return axios.get(`${baseUrl}/enter/exam/list/candidates/info?page=${page}&size=${size}&examId=${id}
    `)
  },
  passCandidate(id) {
    return axios.post(`${baseUrl}/enter/candidate/pass?candidateId=${id}`)
  },
  unpassCandidate(id) {
    return axios.post(`${baseUrl}/enter/candidate/unPass?candidateId=${id}`)
  },
  getResult(page, size, data) {
    return axios.post(`${baseUrl}/enter/exam/search?page=${page}&size=${size}`, data)
  },
  getSearchExam(page, size, name, categoryId) {
    return axios.post(`${baseUrl}/exercise/exam/search/data?examName=${name}&categoryId=${categoryId}&page=${page}&size=${size}`)
  },
  searchCandidate(page, size, data) {
    return axios.post(`${baseUrl}/enter/candidate/search?page=${page}&size=${size}`, data)
  },
  getResultDetail(id) {
    return axios.get(`${baseUrl}/enter/exam/answer?answerPaperId=${id}`)
  },
  getEvaluates() {
    return axios.get(`${baseUrl}/exercise/exam/evaluates/get/auth?page=0&size=1000`)
  },
  getEvaRes(id) {
    return axios.get(`${baseUrl}/enter/exam/evaluation/result?answerPaperId=${id}`)
  },
  getMarkingPapers() {
    return axios.get(`${baseUrl}/enter/exam/marking/papers`)
  },
  getMarkingList(id, page, size) {
    return axios.get(`${baseUrl}/enter/exam/marking/answerPapers?examId=${id}&page=${page}&size=${size}`)
  },
  getMarkingQuestions(id) {
    return axios.get(`${baseUrl}/enter/exam/marking/questions?answerPaperId=${id}`)
  },
  submitMark(aId, qId, score) {
    return axios.post(`${baseUrl}/enter/exam/marked/question?answerPaperId=${aId}&questionId=${qId}&score=${score}`)
  },
  sendExamInfo(candidateDTOList, examName) {
    return axios.post(baseUrl + '/enter/exam/send/exam/info', candidateDTOList, examName)
  },
  getCandidateExcelDesc: `${baseUrl}/files/download/exam/excel`,
  getExamExcelDesc: `${baseUrl}/files/download/candidate/excel`,
  // uploadCandidate: `${baseUrl}/enter/candidate/upload/candidate`,
  uploadCandidate(file) {
    return axios.post(`${baseUrl}/enter/candidate/upload/candidate`, file)
  },
  uploadExam(file, examId) {
    return axios.post(`${baseUrl}/enter/candidate/upload/exam?examId=${examId}&type=1`, file)
  },
  getRank(id, page, size) {
    return axios.get(baseUrl + `/statistic/ability/rank/get?postId=${id}&page=${page}&size=${size}`)
  },
  getAllAppraise(id, page, size) {
    return axios.get(baseUrl + `/appraise/getAllHistory?departmentId=${id}&page=${page}&size=${size}`)
  },
  getAppraise(id) {
    return axios.get(baseUrl + `/appraise/getAppraiser?departmentId=${id}`)
  },
  getRelated(id) {
    return axios.get(baseUrl + `/appraise/getRelatedPerson?departmentId=${id}`)
  },
  getUserAppraise(id, page, size) {
    return axios.get(baseUrl + `/appraise/getUserAppraise?userId=${id}&page=${page}&size=${size}`)
  },
  delByUserId(data) {
    return axios.get(baseUrl + `/appraise/deleteById?ids=${data}`)
  },
  addAppraise(data) {
    return axios.post(baseUrl + '/appraise/add', data)
  },
  editAppraise(paperId, ids) {
    return axios.post(baseUrl + `/appraise/modify/appraises/paperId?paperId=${paperId}`, ids)
  },
  addQuestionnaire(courseId, paperId) {
    return axios.post(baseUrl + `/questionnaire/course/questionnaire/add?courseId=${courseId}&paperId=${paperId}`)
  },
  getQuestionnaire(id) {
    return axios.get(baseUrl + `/questionnaire/course/questionnaire/get?courseId=${id}`)
  },
  delQuestionnaire(id) {
    return axios.post(baseUrl + `/questionnaire/course/questionnaire/delete?questionnaireId=${id}`)
  },
  getQuestionnaireDetail(id) {
    return axios.get(baseUrl + `/questionnaire/details?questionnaireId=${id}`)
  },
  getExamByExamId(examId) {
    return axios.get(`${baseUrl}/exercise/exam/paper/get?examId=${examId}`)
  },
  getPersonExamByExamId(userId, examId) {
    return axios.get(`${baseUrl}/enter/exam/user/answer-papers?userId=${userId}&examId=${examId}`)
  },
  getExamByAnswerPaperId(answerPaperId) {
    return axios.get(`${baseUrl}/enter/exam/answer?answerPaperId=${answerPaperId}`)
  },
  getExamResSection(id) {
    return axios.get(`${baseUrl}/enter/exam/answer/section?answerPaperId=${id}`)
  },
  getExamineesByExamId(examId, page, size, departmentId, searchContent, isAsc, order) {
    return axios.get(
      `${baseUrl}/exam/examinees?examId=${examId}&page=${page}&size=${size}&departmentId=${departmentId}&name=${searchContent}&isAsc=${isAsc}&order=${order}`
    )
  },
  getPaperExams(paperId, page, size, searchContent) {
    return axios.get(`${baseUrl}/exam/paper-exams?paperId=${paperId}&page=${page}&size=${size}&searchContent=${searchContent}`)
  },
  unDeleted(searchContent = '', sort = '', page, size) {
    return axios.get(`${baseUrl}/exam/un-deleted?page=${page}&size=${size}&searchContent=${searchContent}&sort=${sort}`)
  },
  getPaperBankExams(paperBankId, sort, page, size, searchContent) {
    return axios.get(
      `${baseUrl}/exam/paper-bank/exams?paperBankId=${paperBankId}&sort=${sort}&page=${page}&size=${size}&searchContent=${searchContent}`
    )
  },
  getAutoExams(postId, sort, page, size) {
    return axios.get(`${baseUrl}/exam/get/auto/exams?postId=${postId}&sort=${sort}&page=${page}&size=${size}`)
  },
  exportPeoInfo(examId) {
    //导出考试所有答题人员考试信息
    return axios({
      method: 'get',
      url: `${examUrl}/exam/management/download/exam/excel?examId=${examId}`,
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/octet-stream',
        Authorization: 'Bearer ' + (localStorage.getItem('auth') && JSON.parse(localStorage.getItem('auth')).access_token)
      }
    })
  },
  delAutoExam(ids) {
    return axios.post(`${baseUrl}/exam/delete/auto/exam`, ids)
  },
  getAutoExam(form) {
    return axios.get(`${baseUrl}/exam/get/auto/exams?postId=${form.postId}&userName=${form.userName}&page=${form.pageNum}&size=${form.pageSize}`)
  },
  getExamEva(form) {
    return axios.get(`${baseUrl}/exam/evaluation/search?examName=${form.examName}&pageNum=${form.pageNum}&pageSize=${form.pageSize}`)
  },
  examEvaDel(form) {
    return axios.post(`${baseUrl}/exam/evaluation/delete`, form)
  },
  importExam(form) {
    return axios.post(`${baseUrl}/exam/add/exam/report`, form)
  },
  getExamReport(form) {
    return axios.post(`${baseUrl}/exam/get/exam/report`, form)
  },
  getEvaluationList(data) {
    return axios.get(`${baseUrl}/exam/evaluation/get/exam-info?pageNum=${data.pageNum}&pageSize=${data.pageSize}`)
  },
  getEvaByExamId(id) {
    return axios.get(`${baseUrl}/exam/evaluation/get?examId=${id}`)
  },
  getpracticeById(id) {
    return axios.get(`${baseUrl}/practice/${id}`)
  },
  // 更新练习
  updatePracticeByForm(payload) {
    return axios.post(`${baseUrl}/practice/update`, payload)
  },
  // 导出所有参考人员的报告
  importAllReports(id) {
    return axios.get(`${baseUrl}/enter/report/download/exam-export?examId=${id}`)
  },
  //重考
  makeRepeatExam(id, form) {
    return axios.post(`${baseUrl}/exam/repeat/exam?examId=${id}`, form)
  },
  //获取选中的生成报告人数
  selectUserNum(payload) {
    return axios.post(`${baseUrl}/enter/report/random/select/user/num`, payload)
  },
  // 考试详情中技能点数
  abilityTreeData(answerPaperId) {
    return axios.get(`${examUrl}/exam/management/get/relevance/ability?answerPaperId=${answerPaperId}`)
  },
  // 获取测评结果
  getExamResult(jobResumeId) {
    return axios.get(`${baseUrl}/enter/exam/answer/section/by-job-resume-id?jobResumeId=${jobResumeId}`)
  },
  //获取考试信息
  getExamInfo(jobResumeId) {
    return axios.get(`${baseUrl}/enter/exam/user/answer-papers/by-job-resume-id?jobResumeId=${jobResumeId}`)
  },
  //获取分数分布信息
  getScoreInfo(jobResumeId) {
    return axios.get(`${baseUrl}/exam/get/exam/ability/score/by-job-resume-id?jobResumeId=${jobResumeId}`)
  }
}
